import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUpdateOfferInProgress, updateOffer } from '../../OffersPage/OffersPage.duck';
import { createSlug } from '../../../util/urlHelpers';
import routeConfiguration, { draftSlug } from '../../../routing/routeConfiguration';
import {
  allCounterCyclesCompleted,
  getOfferParams,
  getTxImage,
  getTxTitle,
  hasOfferExpired,
} from '../../../util/genericHelpers';
import { Button, IconSpinner } from '../../../components';
import { handleSubmit } from '../ListingPage.shared';
import { getCurrentOfferState } from '../../OffersPage/OffersPage';
import { timeOfDayFromLocalToTimeZone } from '../../../util/dates';
import { isTransitionCounterByCustomer } from '../../../transactions/transactionProcessPurchaseCash';
import { getOfferTransitions } from '../../../transactions/transactionHelpers';
import { getOfferTransaction } from '../ListingPage.duck';

import css from './Offers.module.css';
import OfferForm from './OfferForm';

// Destructure transition constants for ease of use
const {
  TRANSITION_ACCEPT_OFFER,
  TRANSITION_DECLINE_OFFER,
  TRANSITION_COUNTER_OFFER,
  TRANSITION_COUNTER_OFFER_BY_CUSTOMER,
  TRANSITION_ACCEPT_AFTER_COUNTER_OFFER,
  TRANSITION_ACCEPT_AFTER_COUNTER_OFFER_BY_PROVIDER,
  TRANSITION_DECLINE_AFTER_COUNTER_OFFER,
  TRANSITION_DECLINE_AFTER_COUNTER_OFFER_BY_PROVIDER,
} = getOfferTransitions();

function RenderGuestOfferMaybe(props) {
  const {
    history,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
    intl,
  } = props;

  const dispatch = useDispatch();

  // Get the current transaction and update offer progress from the Redux store
  const { transaction: currentTransaction, fetchTransactionInProgress } =
    useSelector(getOfferTransaction) || {};
  const updateOfferInProgress = useSelector(getUpdateOfferInProgress);

  // State to toggle counter offer form
  const [counterToggle, setCounterToggle] = useState(false);

  // Get offer parameters from the current transaction
  const offerParams = getOfferParams(currentTransaction);

  // Function to redirect to checkout with offer price
  const onRedirectToCheckoutWithOfferPrice = async () => {
    const listing = currentTransaction?.listing;
    const author = currentTransaction?.provider;

    const listingParams = {
      id: listing.id.uuid,
      slug: createSlug(listing.attributes.title || draftSlug),
    };

    const commonParams = { params: listingParams, history, routes: routeConfiguration() };
    const listingCountryCode = listing?.attributes?.publicData?.countryCode;

    handleSubmit({
      ...commonParams,
      currentUser,
      callSetInitialValues,
      getListing,
      author,
      onInitializeCardPaymentData,
    })({
      quantity: 1,
      deliveryMethod: 'shipping',
      isOffers: true,
      recipientCountryOrder: listingCountryCode,
      transactionId: currentTransaction?.id,
      offerParams,
    });
  };

  // Function to handle offer updates
  const handleUpdateOffer = async (transition, message, amount) => {
    const { id: transactionId, price: offerPrice } = currentTransaction || {};

    const params = {
      id: transactionId,
      transition,
      message:
        message || intl.formatMessage({ id: 'OffersPage.offerUpdateMessage' }, { offerPrice }),
      offerParams: {
        ...offerParams,
        transactionId: transactionId?.uuid,
        type: determineOfferType(transition),
      },
    };

    if (isCounterOffer(transition)) {
      params.offerParams.offerPrice = amount;
    }

    await dispatch(updateOffer(params));
  };

  // Helper function to determine offer type based on transition
  const determineOfferType = transition => {
    switch (transition) {
      case TRANSITION_ACCEPT_OFFER:
        return 'ACCEPT';
      case TRANSITION_ACCEPT_AFTER_COUNTER_OFFER:
      case TRANSITION_ACCEPT_AFTER_COUNTER_OFFER_BY_PROVIDER:
        return 'ACCEPT_AFTER_COUNTER_OFFER';
      case TRANSITION_DECLINE_OFFER:
      case TRANSITION_DECLINE_AFTER_COUNTER_OFFER:
      case TRANSITION_DECLINE_AFTER_COUNTER_OFFER_BY_PROVIDER:
        return 'DECLINED';
      case TRANSITION_COUNTER_OFFER:
      case TRANSITION_COUNTER_OFFER_BY_CUSTOMER:
        return 'COUNTER';
      default:
        return '';
    }
  };

  // Helper function to check if the transition is a counter offer
  const isCounterOffer = transition =>
    [TRANSITION_COUNTER_OFFER, TRANSITION_COUNTER_OFFER_BY_CUSTOMER].includes(transition);

  // Get offer state data for rendering
  const offerStateData = currentTransaction
    ? getCurrentOfferState(
        currentTransaction?.attributes?.lastTransition,
        currentTransaction,
        currentUser,
        intl
      )
    : null;

  // Determine if various UI elements should be displayed
  const showBuyButton = !!offerStateData?.showBuyButton;
  const showCounterActions = !!offerStateData?.showCounterActions;
  const transactionCreatedAt = currentTransaction?.attributes?.createdAt;
  const createdAt = timeOfDayFromLocalToTimeZone(transactionCreatedAt, 'timezone'); // Adjust timezone as needed
  const hasExpired = hasOfferExpired(createdAt);
  const isCounterOfferByCustomer = isTransitionCounterByCustomer(
    currentTransaction?.attributes?.lastTransition
  );
  const hideCounterAction = allCounterCyclesCompleted(currentTransaction);

  if (fetchTransactionInProgress) {
    return (
      <div className={css.fetchTransactionInProgress}>
        <IconSpinner />
      </div>
    );
  }

  const renderOffersForm = transition => (
    <OfferForm
      isOffersPage={true}
      price={currentTransaction?.listing?.attributes?.price}
      sendButtonText={intl.formatMessage({ id: 'OffersPage.sendCounterOffer' })}
      isCounterOffer={!!counterToggle}
      onSubmit={values => {
        const price = values?.price;
        const amount = price?.amount / 100;
        handleUpdateOffer(
          transition,
          intl.formatMessage(
            { id: 'OffersPage.sendUpdatedCounterOffer' },
            {
              displayName: currentUser?.attributes?.profile?.displayName,
              amount,
            }
          ),
          amount
        );
      }}
    />
  );

  return (
    <div className={css.offerModalContent}>
      <div
        className={css.offerModalImg}
        onClick={() => {
          history.push(
            createResourceLocatorString(
              'ListingPage',
              routeConfiguration(),
              {
                id: currentTransaction?.listing?.id?.uuid || 'draftId',
                slug: createSlug(currentTransaction?.listing?.attributes?.title || draftSlug),
              },
              {}
            )
          );
        }}
      >
        <img src={getTxImage(currentTransaction)} alt={getTxTitle(currentTransaction)} />
      </div>
      <h2>
        {hasExpired
          ? intl.formatMessage(
              { id: 'OffersPage.acceptedOfferExpired' },
              { offerPrice: offerParams?.offerPrice }
            )
          : offerStateData?.title}
      </h2>
      {offerStateData?.description && !hasExpired && <p>{offerStateData?.description}</p>}

      {showBuyButton && !hasExpired && (
        <div className={css.offerButtons}>
          <Button className={css.submitBtn} onClick={onRedirectToCheckoutWithOfferPrice}>
            {intl.formatMessage({ id: 'OffersPage.buyNow' })}
          </Button>
        </div>
      )}

      {showCounterActions &&
        !hasExpired &&
        (updateOfferInProgress ? (
          <IconSpinner />
        ) : !counterToggle ? (
          <div className={css.offerButtons}>
            <Button
              className={css.submitBtn}
              onClick={() =>
                handleUpdateOffer(
                  isCounterOfferByCustomer
                    ? TRANSITION_ACCEPT_AFTER_COUNTER_OFFER_BY_PROVIDER
                    : TRANSITION_ACCEPT_AFTER_COUNTER_OFFER
                )
              }
            >
              {intl.formatMessage({ id: 'OffersPage.accept' })}
            </Button>
            <Button
              onClick={() =>
                handleUpdateOffer(
                  isCounterOfferByCustomer
                    ? TRANSITION_DECLINE_AFTER_COUNTER_OFFER_BY_PROVIDER
                    : TRANSITION_DECLINE_AFTER_COUNTER_OFFER
                )
              }
            >
              {intl.formatMessage({ id: 'OffersPage.decline' })}
            </Button>
            {!hideCounterAction && (
              <Button onClick={() => setCounterToggle(!counterToggle)}>
                {intl.formatMessage({ id: 'OffersPage.counter' })}
              </Button>
            )}
          </div>
        ) : (
          <div className={css.counter}>
            {renderOffersForm(TRANSITION_COUNTER_OFFER_BY_CUSTOMER)}
          </div>
        ))}
    </div>
  );
}

export default RenderGuestOfferMaybe;
