import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../../../util/reactIntl';
import {
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  composeValidators,
  emailFormatValid,
  maxLength,
  minLength,
  required,
  requiredStringNoTrim,
} from '../../../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../../../components';

import css from './GuestForm.module.css';

const GuestFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        claimUserInProgress,
        saveEmailError,
      } = fieldRenderProps;

      const createAccountMessage = intl.formatMessage({
        id: 'GuestForm.createAccount',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = claimUserInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const emailLabel = intl.formatMessage({
        id: 'ContactDetailsForm.emailLabel',
      });

      const emailPlaceholder = '';

      const emailRequiredMessage = intl.formatMessage({
        id: 'ContactDetailsForm.emailRequired',
      });
      const emailRequired = required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'ContactDetailsForm.emailInvalid',
      });
      const emailValid = emailFormatValid(emailInvalidMessage);

      const passwordLabel = intl.formatMessage({
        id: 'PasswordResetForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'PasswordResetForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'PasswordResetForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'PasswordResetForm.passwordTooShort',
        },
        {
          minLength: PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'PasswordResetForm.passwordTooLong',
        },
        {
          maxLength: PASSWORD_MAX_LENGTH,
        }
      );
      const passwordRequired = requiredStringNoTrim(passwordRequiredMessage);
      const passwordMinLength = minLength(passwordMinLengthMessage, PASSWORD_MIN_LENGTH);
      const passwordMaxLength = maxLength(passwordMaxLengthMessage, PASSWORD_MAX_LENGTH);

      // First name
      const firstNameLabel = intl.formatMessage({
        id: 'ProfileSettingsForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'ProfileSettingsForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'ProfileSettingsForm.firstNameRequired',
      });
      const firstNameRequired = required(firstNameRequiredMessage);

      // Last name
      const lastNameLabel = intl.formatMessage({
        id: 'ProfileSettingsForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'ProfileSettingsForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'ProfileSettingsForm.lastNameRequired',
      });
      const lastNameRequired = required(lastNameRequiredMessage);

      const emailTakenErrorText =
        saveEmailError?.type === 'error'
          ? intl.formatMessage({ id: 'ContactDetailsForm.emailTakenError' })
          : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.nameContainer}>
            <FieldTextInput
              className={css.firstName}
              type="text"
              id="firstName"
              name="firstName"
              label={firstNameLabel}
              placeholder={firstNamePlaceholder}
              validate={firstNameRequired}
            />
            <FieldTextInput
              className={css.lastName}
              type="text"
              id="lastName"
              name="lastName"
              label={lastNameLabel}
              placeholder={lastNamePlaceholder}
              validate={lastNameRequired}
            />
          </div>
          <FieldTextInput
            type="email"
            name="email"
            className={css.email}
            id={formId ? `${formId}.email` : 'email'}
            label={emailLabel}
            placeholder={emailPlaceholder}
            validate={composeValidators(emailRequired, emailValid)}
          />

          <FieldTextInput
            className={css.password}
            type="password"
            id={formId ? `${formId}.password` : 'password'}
            name="password"
            autoComplete="new-password"
            label={passwordLabel}
            placeholder={passwordPlaceholder}
            validate={composeValidators(passwordRequired, passwordMinLength, passwordMaxLength)}
          />

          <div className={css.error}>{emailTakenErrorText}</div>
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={claimUserInProgress}
            disabled={submitDisabled}
          >
            {createAccountMessage}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

GuestFormComponent.defaultProps = { className: null, rootClassName: null, sendReviewError: null };

const { bool, func, string } = PropTypes;

GuestFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
};

const GuestForm = compose(injectIntl)(GuestFormComponent);
GuestForm.displayName = 'GuestForm';

export default GuestForm;
