import React, { useEffect, useState } from 'react';
import { Modal } from '../../../components';
import PropTypes from 'prop-types';
import OfferForm from './OfferForm';
import moment from 'moment';
import { createResourceLocatorString } from '../../../util/routes';
import { OFFER_CONSTANTS, OPEN_OFFER_MODAL } from '../../../util/enums';
import GuestForm from '../../TransactionPage/GuestModal/GuestForm/GuestForm';
import { createSlug, parse } from '../../../util/urlHelpers';
import RenderGuestOfferMaybe from './RenderGuestOfferMaybe';
import { encryptString } from '../../../util/genericHelpers';

function OfferModal(props) {
  const {
    onManageDisableScrolling,
    onOpenOffersModal,
    isOffersModalOpen,
    intl,
    config,
    history,
    routeConfiguration,
    price,
    listingTitle,
    authorDisplayName,
    listing,
    currentUser,
    sendInquiryInProgress,
    onSendInquiry,
    selectedVariant,
    location,
    listingBaseParams,
    isGuest,
    onClaimAccount,
    onUpdateProfile,
    claimUserInProgress,
    saveEmailError,
    variantListings,
    marketplaceRootURL,
    isAuthenticated,
    onLogin,
    callSetInitialValues,
    onInitializeCardPaymentData,
    getListing,
    onQueryTransactionById,
  } = props;

  const search = parse(location.search);
  const isGuestOffersView = search?.txId;

  useEffect(() => {
    if (isGuestOffersView) {
      onOpenOffersModal(true);
      onQueryTransactionById({ txId: search.txId });
    }
  }, [isGuestOffersView]);

  useEffect(() => {
    if (!isAuthenticated && search.view) {
      onLogin(search.view, process.env.REACT_APP_GUEST_PASSWORD).then(() => {
        history.push(
          createResourceLocatorString(
            'CheckoutPage',
            routeConfiguration,
            { id: selectedVariant.id.uuid, slug: createSlug(listingTitle) },
            {}
          )
        );
      });
    }
  }, [isAuthenticated]);

  const [confirmationModalParams, setConfirmationModalParams] = useState(false);
  const [showAccountCreationForm, setShowAccountCreationForm] = useState(false);

  const shouldOpenOfferModal = location?.hash === OPEN_OFFER_MODAL;

  const onSendOfferRequest = async values => {
    const { price } = values;
    const offerPrice = Number(price?.amount / 100).toFixed(2);

    try {
      await onSendInquiry(
        selectedVariant,
        intl.formatMessage({ id: 'OfferModal.sendOffferMessage' }, { offerPrice }),
        {
          offerPrice,
          authorId: currentUser?.id?.uuid,
          createdAt: moment().unix(),
          type: OFFER_CONSTANTS.INITIAL,
          variant: selectedVariant,
          guestTransactionParams: isGuest
            ? {
                guestEmail: values?.email,
                listingPath: `${marketplaceRootURL}${location.pathname}?guestEmail=${encryptString(
                  currentUser?.attributes?.email,
                  process.env.REACT_APP_ENCRYPTION_KEY
                )}`,
                listingTitle,
              }
            : null,
        }
      );
      if (isGuest) {
        onUpdateProfile({
          privateData: {
            guestEmail: values?.email,
          },
        });
        setConfirmationModalParams({ offerPrice, guestEmail: values?.email });
      } else {
        history.push(createResourceLocatorString('OffersPage', routeConfiguration, {}, {}));
      }
    } catch (error) {
      onOpenOffersModal(false);
    }
  };

  const renderForm = showAccountCreationForm ? (
    <GuestForm
      initialValues={{ email: confirmationModalParams?.guestEmail }}
      saveEmailError={saveEmailError}
      onSubmit={params => {
        onClaimAccount(params).then(() => {
          onOpenOffersModal(false);
          setShowAccountCreationForm(false);
          if (shouldOpenOfferModal) {
            history.push(listingBaseParams);
          }
        });
      }}
      claimUserInProgress={claimUserInProgress}
    />
  ) : (
    <OfferForm
      onSubmit={onSendOfferRequest}
      config={config}
      price={price}
      listingTitle={listingTitle}
      setShowAccountCreationForm={setShowAccountCreationForm}
      isGuest={isGuest}
      confirmationModalParams={confirmationModalParams}
      inquiryInProgress={sendInquiryInProgress}
      authorDisplayName={authorDisplayName}
    />
  );

  return (
    <div>
      <Modal
        id="OfferModal"
        isOpen={!!isOffersModalOpen || shouldOpenOfferModal}
        onClose={() => {
          onOpenOffersModal(false);
          if (shouldOpenOfferModal) {
            history.push(listingBaseParams);
          }
        }}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        {isGuestOffersView ? (
          <RenderGuestOfferMaybe
            variantListings={variantListings}
            history={history}
            intl={intl}
            callSetInitialValues={callSetInitialValues}
            onInitializeCardPaymentData={onInitializeCardPaymentData}
            getListing={getListing}
            currentUser={currentUser}
          />
        ) : (
          renderForm
        )}
      </Modal>
    </div>
  );
}

OfferModal.propTypes = {
  onManageDisableScrolling: PropTypes.func.isRequired,
  onOpenOffersModal: PropTypes.func.isRequired,
  isOffersModalOpen: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  routeConfiguration: PropTypes.array.isRequired,
  listingTitle: PropTypes.string.isRequired,
  authorDisplayName: PropTypes.string.isRequired,
  listing: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  sendInquiryInProgress: PropTypes.bool.isRequired,
  onSendInquiry: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  listingBaseParams: PropTypes.string.isRequired,
};

export default OfferModal;
